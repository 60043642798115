import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { Environment, EnvironmentName } from "@olb/olb-mfe-common";
import { setGlassboxBreadcrumb } from "./analytics";

export function initDatadogRum(sessionId: string) {
  const environmentName = Environment.environmentConfig.environmentName;

  if (environmentName === EnvironmentName.LOCAL) {
    console.warn(`Skipping Datadog RUM for ${environmentName} environment`);
    return;
  }

  // Datadog ENTERPRISE > Datadog RUM > Application: OLB
  datadogRum.init({
    applicationId: "9e5b0e65-1788-4ad0-8930-bdff1eda5bb8",
    clientToken: "pub55307355f52de676df02f9f6c37e81d6",
    site: "datadoghq.com",
    service: "olb",
    env: environmentName,
    // Version comes from standard build environment npm/yarn variable brought in via Webpack's EnvironmentPlugin
    version: `olb-root-${process.env.npm_package_version}`,
    // Sessions
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    // Traces
    allowedTracingUrls:
      // Disabled in sandbox, as tracing currently breaks CORS for login (sandbox uses dev URLs for login)
      environmentName === EnvironmentName.SANDBOX
        ? []
        : [/https:\/\/.*\.(qa)?citizensbankonline\.com/],
    traceSampleRate: 100,
    // Exclude optional async items (e.g., analytics, glassbox, etc.) from page activity / detecting when page is stable
    // See https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#how-page-activity-is-calculated
    excludedActivityUrls: [
      /profiling\.(qa)?citizensbankonline\.com/,
      /\.liveperson\.net/,
      /\.lpsnmedia\.net/,
      /\.launchdarkly\.com/,
      /\.pega\.com/,
      /\.kampyle\.com/, // Medallia
      /\.adobedtm\.com/,
      /\.glassboxdigital\.io/,
      /smetrics\.citizensbank\.com/,
      /\.omtrdc\.net/,
      /\.branch\.io/,
      /app\.link/,
    ],
    // Mask everything in production until we have specific masking in place, but show everything in lower environments
    defaultPrivacyLevel:
      environmentName === EnvironmentName.PROD ? "mask" : "allow",
    beforeSend: (event, context: any) => {
      if (event.type === "resource") {
        // Add server timing from context, otherwise it's not accessible for queries in the Datadog UI
        const performanceServerTiming = (context as any).performanceEntry
          ?.serverTiming as any[];

        const serverTiming = {};
        performanceServerTiming?.forEach((entry) => {
          serverTiming[entry.name] = {
            description: entry.description,
            duration: entry.duration,
          };
        });

        event.context = {
          ...event.context,
          serverTiming,
        };
      }
    },
  });

  // Datadog browser logging attached to RUM sessions
  datadogLogs.init({
    clientToken: "pub55307355f52de676df02f9f6c37e81d6",
    site: "datadoghq.com",
    service: "olb",
    env: environmentName,
    // Version comes from standard build environment npm/yarn variable brought in via Webpack's EnvironmentPlugin
    version: `olb-root-${process.env.npm_package_version}`,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: [], // Be default use datadogLogs.logger explicitly instead
    sessionSampleRate: 100,
  });

  setOlbSessionIdsInRumContext(sessionId);
  setGlassboxBreadcrumb(
    "Datadog Session ID",
    datadogRum.getInternalContext()?.session_id,
  );

  datadogRum.startSessionReplayRecording();
}

// TODO - make sure latest session id is being added to this
function setOlbSessionIdsInRumContext(sessionId: string) {
  // Internal context may be undefined in some cases, see https://github.com/DataDog/browser-sdk/issues/798
  // This seems to be the case in Datadog synthetic tests for some reason...
  const datadogSessionId = datadogRum.getInternalContext()?.session_id;

  // Update list of OLB session IDs associated with the current datadog RUM session
  // Local storage is used since the datadog RUM session can last longer than a browser session (i.e., session storage)
  const datadogOlbSessionsLocalStorageKey = "citizens.olb.datadogOlbSessions";
  const datadogOlbSessionsValuePrefix = `datadog_session_${datadogSessionId}:`;

  let datadogOlbSessions =
    localStorage.getItem(datadogOlbSessionsLocalStorageKey) || "";

  // Check if we're associating the OLB sessions ID to an existing datadog session
  if (datadogOlbSessions.startsWith(datadogOlbSessionsValuePrefix)) {
    // Add current OLB session if it's not already associated
    if (sessionId !== "" && datadogOlbSessions.indexOf(sessionId) === -1) {
      datadogOlbSessions += `,${sessionId}`;
    }
  } else {
    // Reset value, we're in a new datadog session
    datadogOlbSessions = `${datadogOlbSessionsValuePrefix}${sessionId}`;
  }

  // Update our local storage item
  localStorage.setItem(datadogOlbSessionsLocalStorageKey, datadogOlbSessions);
  // Update our datadog session context with the updated list of all associated OLB session IDs
  datadogRum.setGlobalContextProperty(
    "citizens.olb.sessionIds",
    datadogOlbSessions.replace(datadogOlbSessionsValuePrefix, ""),
  );
}
